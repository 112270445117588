import React from 'react'
import intl from 'react-intl-universal'
import { graphql } from 'gatsby'
import { Formik } from 'formik'
import { LOCALES, LocaleService, LocationService } from '@raysync/common/services'
import { range } from '@raysync/common/utils'
import { H2, Button, Toast, Select, Option, Billboard, Partner, HideAtMobile } from '@raysync/common/components'
import Layout from '../components/layout'
import {
  Input,
  ButtonMask,
  FormItem,
  ApplyWrap,
  DescriptionContent,
  ApplyContainer,
  FormItemContainer,
  SuccessContainer,
  ButtonContainer,
  IntroContainer,
  PartnerWrap,
  ApplyText,
  SubText,
} from '../style/apply.atoms'
import API from '@raysync/common/services/api'
import RightIcon from '../images/apply/right.png'
const PHONE_REGEX = /^1\d{10}$/
//transPath transFreque transDataAmount
const AreaMap = LocaleService.isForeign ? 2 : 1

const pageTypeMap = Object.freeze({
  product1: '软件-大文件传输系统',
  product2: '软件-SDK传输应用集成',
  product3: '软件-镭速Proxy代理加速',
  product4: '软件-镭速FTP加速 ',
  product5: '软件-RaySSH加速',
  solution1: '软件-海量数据传输',
  solution2: '软件-跨国数据传输',
  solution3: '软件-超大文件传输',
  solution4: '软件-数据同步',
  solution5: '软件-数据迁移',
  solution6: '软件-数据分发',
  solution7: '软件-数据协作',
  ad: '软件-广告媒体行业',
  gene: '软件-基因行业',
  car: '软件-汽车行业',
  moive: '软件-影视行业',
  internet: '软件-IT互联网行业',
  government: '软件-政府机构',
  financing: '软件-金融行业',
  manufacturing: '软件-生产制造业',
  network1: '软件-IPv6',
  network2: '软件-SD-WAN',
  network3: '软件-点对点专线',
  network4: '软件-最后一公里',
  network5: '软件-企业互联专线',
  network6: '软件-弹性混合云',
  wechat: '微信公众号',
  gy: '贵阳展会',
  bj: '北京展会',
})

const userTypeMap = Object.freeze({
  enterprise: '企业用户',
  college: '高校组织',
  social: '社会组织',
  personal: '个人用户',
  confidential: '保密用户',
})

const paths = [
  {
    intlKey: 'apply.transmission-path.option1',
    value: 1,
  },
  {
    intlKey: 'apply.transmission-path.option2',
    value: 2,
  },
  {
    intlKey: 'apply.transmission-path.option3',
    value: 3,
  },
  {
    intlKey: 'apply.transmission-path.option4',
    value: 4,
  },
  {
    intlKey: 'apply.transmission-path.option5',
    value: 5,
  },
]

const Apply = props => {
  const { location, data } = props
  let type = 'apply'
  if (location.state && location.state.type) {
    type = location.state.type
  }

  const [userType, UserTypeChange] = React.useState('enterprise')

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    if (LocaleService.currentLocale === LOCALES.ZH && !PHONE_REGEX.test(values.phone)) {
      Toast.error(intl.get('apply.placeholder.phone.error'))
      setSubmitting(false)
      return
    }
    const type = LocationService.getLocationSearchParams().get('type')
    setSubmitting(true)
    let data = {
      ...values,
      company: values.company,
      userType: userTypeMap[values.userType],
      transData: values.transDataAmount ? values.transDataAmount + values.transDataUnit : '',
      transFreque: values.transFreque ? intl.get('apply.placeholder.mbs.times', { times: values.transFreque }) : '',
      area: AreaMap,
      type: pageTypeMap[type] || type || '软件官网',
      transPath: values.transPath.join(','),
      applyType: 1,
    }

    API.apply
      .add(data)
      .then(response => {
        let data = response.data
        if (data.code === 0) {
          reportBingEvent()
          Toast.info(intl.get('apply.submit.success'))
          resetForm()
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }
  const reportBingEvent = () => {
    if (typeof window !== 'undefined' && process.env.GATSBY_ENV !== 'development') {
      try {
        window._hmt.push(['_trackEvent', 'apply', 'click', LocaleService.currentLocale])
        window._agl && window._agl.push(['track', ['success', { t: 3 }]])
      } catch (error) {
        console.log(error)
      }
    }
  }

  const getInitialValues = type => {
    let initialValues = {
      name: '',
      phone: '',
      mobileAreaCode: LocaleService.currentLocale === LOCALES.ZH ? '86' : '1',
      concation: '',
      company: '',
      message: '',
      userType: 'enterprise',
      transFreque: '',
      transDataAmount: '',
      transDataUnit: 'GB',
      transPath: [],
    }
    // type === 'solution' && (initialValues.industry = intl.get('apply.industry.option1'))
    return initialValues
  }

  const initialValues = getInitialValues(type)
  const title = {
    apply: intl.get('apply.title1'),
    prompt: intl.get('apply.title2'),
    solution: intl.get('apply.title3'),
  }[type]
  const userTypeInfo = {
    enterprise: { placeholder: intl.get('apply.placeholder.userType2') },
    college: { placeholder: intl.get('apply.placeholder.userType3') },
    social: { placeholder: intl.get('apply.placeholder.userType4') },
    personal: { placeholder: intl.get('apply.placeholder.userType4') },
    confidential: { placeholder: intl.get('apply.placeholder.userType4') },
  }[userType]
  const introList = [
    '最高传输速度比FTP/HTTP快100倍!',
    '超低延迟和丢包，不受任何环境因素影响!',
    'TLS+AES-256多重加密技术，网银级安全有保障!',
    '可快速与企业现有系统无缝集成，低成本，高效率!',
    '支持多种操作系统，一键快速部署!',
  ]
  const getSuccessContainer = () => {
    return (
      <>
        <SuccessContainer>
          <H2>{title}</H2>
          <ApplyText>
            <span>镭速暂不接受个人用户申请请求，</span>仅面向
            <br />
            企业、社会和高校组织用户提供服务
          </ApplyText>
          <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, errors, handleChange, submitForm, setFieldValue, handleBlur, handleSubmit, isSubmitting }) => {
              const disabled = !values.phone
              return (
                <form onSubmit={handleSubmit}>
                  <>
                    <FormItem key={'userType'}>
                      <Select
                        name='userType'
                        onChange={value => {
                          setFieldValue('userType', value)
                          UserTypeChange(value)
                        }}
                        onBlur={handleBlur}
                        value={values.userType}
                      >
                        <Option value='enterprise' label={intl.get('apply.userType.option2')}>
                          {intl.get('apply.userType.option2')}
                        </Option>
                        <Option value='college' label={intl.get('apply.userType.option3')}>
                          {intl.get('apply.userType.option3')}
                        </Option>
                        <Option value='social' label={intl.get('apply.userType.option4')}>
                          {intl.get('apply.userType.option4')}
                        </Option>
                        <Option value='confidential' label={intl.get('apply.userType.option4')}>
                          保密用户
                        </Option>
                      </Select>
                    </FormItem>
                    {['enterprise', 'college', 'social'].includes(values.userType) && (
                      <FormItem key={'personal'}>
                        <Input
                          placeholder={userTypeInfo.placeholder}
                          maxLength='50'
                          name='company'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.company}
                        />
                      </FormItem>
                    )}

                    <FormItemContainer required>
                      <Input
                        placeholder={intl.get('apply.placeholder.phone')}
                        name='phone'
                        maxLength='20'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        isError={errors.phone}
                      />
                    </FormItemContainer>
                    <FormItem key={'concation'}>
                      <Input
                        placeholder={intl.get('apply.placeholder.concation')}
                        name='concation'
                        maxLength='50'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.concation}
                      />
                    </FormItem>
                  </>
                  <SubText>请确保您填写的联系方式无误，以便我们第一时间联系到您</SubText>

                  <ButtonContainer>
                    {disabled && (
                      <ButtonMask
                        onClick={() => {
                          Toast.error(intl.get('apply.submit.tips'))
                        }}
                      ></ButtonMask>
                    )}
                    {/* 提交按钮 */}
                    <Button
                      type='submit'
                      fullWidth
                      id={process.env.GATSBY_ENV !== 'development' ? 'apply' : ''}
                      size='large'
                      disabled={disabled}
                      loading={isSubmitting}
                    >
                      {intl.get('apply.submit')}
                    </Button>
                  </ButtonContainer>
                </form>
              )
            }}
          </Formik>
        </SuccessContainer>
      </>
    )
  }
  return (
    <Layout pageType='apply' location={location} isWithoutConcactBar isWithoutFooter={true}>
      <ApplyWrap>
        <ApplyContainer>
          <DescriptionContent>
            <div>
              <H2 className='h2'>
                <span>世界500强优选的</span> 数据传输软件厂商
              </H2>
              <IntroContainer>
                {introList.map(i => (
                  <div key={i}>
                    <img src={RightIcon} alt=' ' />
                    <p>{i}</p>
                  </div>
                ))}
              </IntroContainer>
            </div>
          </DescriptionContent>
          {getSuccessContainer()}
        </ApplyContainer>
        <PartnerWrap>
          <Partner />
        </PartnerWrap>
        <HideAtMobile>
          <Billboard fluid={data.applyBg.childImageSharp.fluid} />
        </HideAtMobile>
      </ApplyWrap>
    </Layout>
  )
}

export default Apply
export const query = graphql`
  query {
    applyBg: file(relativePath: { regex: "/apply/apply_bg/" }) {
      ...fluidImage
    }
  }
`
